import React, { useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { Sphere, Html, OrbitControls } from '@react-three/drei';
import * as THREE from 'three';
import ThreeSixtyLGR from "../../assets/360Pics/360 PICS FINAL/Final/LGR Roadside Final.png"
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom

interface HotspotProps {
    position: [number, number, number];
    onClick: () => void;
    label: string;
}

const Hotspot: React.FC<HotspotProps> = ({ position, onClick, label }) => {
    const [isAnimating, setIsAnimating] = useState(false);

    const handleClick = () => {
        setIsAnimating(true);
        // Trigger the animation and reset after a short delay
        setTimeout(() => {
            onClick(); // Perform the click action (navigate, etc.)
        }, 500); // The animation duration
        setTimeout(() => {
            setIsAnimating(false); // Reset the animation
        }, 700); // Delay to match the animation time
    };

    return (
        <Html position={position}>
            <button
                className={`bg-white text-black p-2 rounded-full shadow-lg hover:bg-gray-200 transition duration-300 ${isAnimating ? 'hotspot-animation' : ''}hotspot-jump`}
                onClick={handleClick}
                style={{
                    width: '150px', // Adjust the width here
                    height: '50px', // Adjust the height here
                    fontSize: '14px', // Adjust font size
                }}
            >
                {label}
            </button>
        </Html>
    );
};

const ThreeSixtyLGROutside: React.FC = () => {
    const [currentImage, setCurrentImage] = useState<string>(ThreeSixtyLGR);
    const [isTransitioning, setIsTransitioning] = useState<boolean>(false);
    const navigate = useNavigate(); // Use the useNavigate hook for navigation

    // Load the texture dynamically based on the current image state
    const texture = new THREE.TextureLoader().load(currentImage);

    const handleHotspotClick = (image: string, path: string) => {
        setIsTransitioning(true); // Start the zoom-in transition
        setCurrentImage(image);

        // Start the transition
        setTimeout(() => {
            navigate(path); // Navigate to the new page after the transition duration
        }, 500); // Match this with the transition duration
    };

    return (
        <div className={`w-full h-screen bg-black transition-opacity duration-500 ${isTransitioning ? 'opacity-0' : 'opacity-100'}`}
            style={{ position: 'relative' }}
        >

            <p className='text-white'>
                The Tour still has bugs, since the developer is working on some updates. <br />Thank you for your understanding.
            </p>

            <Canvas
                camera={{
                    position: [80, 0, 10], // Adjust this value to position the camera
                    fov: 75, //Field of View (adjust as needed)
                    near: 0.1,
                    far: 10000
                }}
            >
                <Sphere args={[500, 60, 40]} scale={[-1, 1, 1]}>
                    <meshBasicMaterial map={texture} side={THREE.DoubleSide} />
                </Sphere>

                {/* Hotspots with navigation */}
                <Hotspot
                    position={[-570, 30, 150]}
                    label="Entrance ↑"
                    onClick={() => handleHotspotClick('/360-LGR-Entrance', '/360-LGR-Entrance')}
                />

                {/* Example text message on the tour */}
                <Html position={[-520, -120, -250]}>
                    <style>
                        {`
            @keyframes hotspot-jump {
                0% {
                    transform: translateY(0);
                }
                50% {
                    transform: translateY(-10px);  /* Jump upwards */
                }
                100% {
                    transform: translateY(0);
                }
            }
        `}
                    </style>
                    <div style={{
                        fontSize: '18px',
                        color: 'white',
                        backgroundColor: 'rgba(0, 0, 0, 0.7)',
                        padding: '10px',
                        borderRadius: '15px',
                        width: '500px',  // Set a fixed width for the box
                        animation: 'hotspot-jump 1.5s ease-in-out infinite'
                    }}>
                        <strong>Welcome to the LGR Condotel 360° Tour!</strong>
                        <p>The developer is still working on this page so you will experience bugs while navigating.</p>
                    </div>
                </Html>


                <OrbitControls enableZoom={false} />
            </Canvas>
        </div>
    );
};

export default ThreeSixtyLGROutside;
