import { Suspense } from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import SpinnerLoading from './Components/Spinner'
import { Amenities, Blog, BlogLanding, Contact, Career, Feedbacksample, NewGallery2025, Gallery, Home, Journal, Management, Navbar, NotFound, Promos, Reservation, Room, SkyLounge, Spa, Phase1, Phase2, CoffeeLounge /*Zermatt*/ } from './Routes/LazyImports/Import'
import Footer from './Components/Footer'
import Feedback from './Pages/Feedback/Index'
import BlogAdmin from './Pages/Admin/Blog'
import Dashboard from './Pages/Admin/Dashboard'
import SmtpAdmin from './Pages/Admin/DashboardSuper'
import DriverAcc from './Pages/Admin/Driver'
import GalleryAdmin from './Pages/Admin/Gallery'
import JournalAdmin from './Pages/Admin/Journal'
import MaintenanceAcc from './Pages/Admin/Maintenance'
import PromoAdmin from './Pages/Admin/Promo'
import ReferalPortal from './Pages/Admin/Referral Portal/referralPortal'
import Login from './Pages/Auth/Login/Index'
import DriverNavbar from './Pages/Portals/Drivers Portal/DriverNav'
import DriverPortal from './Pages/Portals/Drivers Portal/DriverPortal'
import MaintenanceNavbar from './Pages/Portals/Maintenance/MaintenanceNav'
import MaintenancePortal from './Pages/Portals/Maintenance/MaintenancePortal'
//import NewMaintenancePortal from './Pages/Portals/Maintenance/NewPortalMaintenance'
import Referral from './Pages/Portals/Referral/Referral'
import Report from './Pages/Admin/Report'
import Register from './Pages/Auth/Resigter/Register'
import ReservationOnOff from './Pages/Admin/ReservationOnOff'
import ThreeSixtyLGROutside from './Pages/360 Tour/LGR360-Outside'
import ThreeSixtyLGRPool1 from './Pages/360 Tour/LGR360-1st-Floor-Pool'
import ThreeSixtyLGRLobby from './Pages/360 Tour/LGR360-Lobby'
import ThreeSixtyLGRSalesOffice from './Pages/360 Tour/LGR360-Sales-Office'
import ThreeSixtyLGRCoffeeLounge from './Pages/360 Tour/LGR360-Coffee-Lounge'
import ThreeSixtyLGRLobbyExt from './Pages/360 Tour/LGR360-Lobby-Ext'
import ThreeSixtyLGRUnderWaterPool from './Pages/360 Tour/LGR360-Underwater'
import ThreeSixtyLGREntrance from './Pages/360 Tour/LGR360-Entrance'
import ThreeSixtyLGRWaitingArea from './Pages/360 Tour/LGR360-WaitingArea'
import ThreeSixtyLGRToElev from './Pages/360 Tour/LGR360-ToElevators'
import ThreeSixtyLGR11thFloor from './Pages/360 Tour/LGR360-11th-Floor'
import ThreeSixtyLGR11thFloor1 from './Pages/360 Tour/LGR360-11th-Pool'
import ThreeSixtyLGRPoolFromCL from './Pages/360 Tour/LGR360-Pool-From-CL'
import ThreeSixtyLGR11thFloor3 from './Pages/360 Tour/LGR360-1st-Pool-2'
import ThreeSixtyLGR11thFloor4 from './Pages/360 Tour/LGR360-11th-SunBath'
import ThreeSixtyLGR11thFloor5 from './Pages/360 Tour/LGR360-11th-Going-Gym'
import ThreeSixtyLGRGym from './Pages/360 Tour/LGR360-Gym'
import ThreeSixtyLGR1stPoolBack from './Pages/360 Tour/LGR360-1st-PoolBack'
import ThreeSixtyLGRGameRoom from './Pages/360 Tour/LGR360-Gameroom'
import ThreeSixtyInsideElevator from './Pages/360 Tour/LGR360-InsideElevator'
import ThreeSixty15thFloor from './Pages/360 Tour/LGR360-15th-Floor'
import ThreeSixty15thFloor2 from './Pages/360 Tour/LGR360-15th-Floor2'
import ThreeSixty15thFloor3 from './Pages/360 Tour/LGR360-15th-Floor3'
import ThreeSixty15thFloor4 from './Pages/360 Tour/LGR360-15th-Floor4'
import ThreeSixty15thFloor5 from './Pages/360 Tour/LGR360-15th-Floor5'
import ThreeSixty15thFloor6 from './Pages/360 Tour/LGR360-15th-Floor6'
import ThreeSixty15thFloor7 from './Pages/360 Tour/LGR360-15th-Floor7'
import ThreeSixty15thFloor8 from './Pages/360 Tour/LGR360-15th-Floor8'
import ThreeSixty16thFloor from './Pages/360 Tour/LGR360-16th-Floor'
import ThreeSixty16thFloorCityView from './Pages/360 Tour/LGR360-16th-Floor-CityView'
import ThreeSixty16thFloor2 from './Pages/360 Tour/LGR360-16th-Floor2'
import ThreeSixty16thFloor3 from './Pages/360 Tour/LGR360-16th-Floor3'
import ThreeSixty16thFloorBalcony from './Pages/360 Tour/LGR360-Rooftop-Balcony'
import ThreeSixtySLDining from './Pages/360 Tour/LGR360-SkyLoungeDining'
import ThreeSixtySLDining2 from './Pages/360 Tour/LGR360-SkyLoungeDining2'
import ThreeSixty15thFloorPool from './Pages/360 Tour/LGR360-15thFloor-Pool'
import ThreeSixtyConferenceRoom from './Pages/360 Tour/LGR360-ConferenceRoom'
import ThreeSixtyPoolCityView from './Pages/360 Tour/LGR360-Pool-CityView'

const currentURL = window.location.href;

function App() {
  return (
    <div className=' font-sans'>
      <BrowserRouter>
        <Suspense fallback={<SpinnerLoading />}>
          <Routes>
            <Route path='/admin/login' element={<Login />} />
            {localStorage.getItem('token') && (
              <>
                <Route path='/admin/blog' element={<BlogAdmin />} />
                <Route path='/admin/promo' element={<PromoAdmin />} />
                <Route path='/admin/journal' element={<JournalAdmin />} />
                <Route path='/admin/dashboard' element={<Dashboard />} />
                <Route path='/admin/portal/driver' element={<DriverAcc />} />
                <Route path='/admin/gallery' element={<GalleryAdmin />} />
                <Route path='/admin/smtp' element={<SmtpAdmin />} />
                <Route path='/admin/portal/maintenance' element={<MaintenanceAcc />} />
                <Route path='/admin/report' element={<Report />} />
                <Route path='/admin/referral' element={<ReferalPortal />} />
                <Route path='/admin/register' element={<Register />} />
                <Route path='/admin/togglereservation' element={<ReservationOnOff />} />
              </>
            )}
            <Route path='/portal/driver' element={<DriverNavbar />}>
              <Route index element={<DriverPortal />} />
            </Route>

            <Route path='/portal/maintenance' element={<MaintenanceNavbar />}>
              <Route index element={<MaintenancePortal />} />
            </Route>
            
            

            <Route path='/referral' element={<Referral />} />
            <Route path='/feedback' element={<Feedbacksample />} />
            
            <Route path='/' element={<Navbar />}>
              <Route index element={<Home />} />
              <Route path='/journals' element={<Journal />} />
              <Route path='/promos' element={<Promos />} />
              <Route path='/Phase1' element={<Phase1 />} />
              <Route path='/Phase2' element={<Phase2 />} />
              <Route path='/room' element={<Room />} />
              <Route path='/amenities' element={<Amenities />} />
              <Route path='/skylounge' element={<SkyLounge />} />
              <Route path='/coffeelounge' element={<CoffeeLounge />} />
              <Route path='/spa' element={<Spa />} />
              <Route path='/blogs' element={<Blog />} />
              <Route path='/management' element={<Management />} />
              <Route path='/contact' element={<Contact />} />
              <Route path='/career' element={<Career />} />
              <Route path='/reservation' element={<Reservation />} />
              <Route path='/blog' element={<BlogLanding />} />
              <Route path='/gallery' element={<NewGallery2025 />} />
              <Route path='/360-LGR-Outside' element={<ThreeSixtyLGROutside />} />
              <Route path='/360-LGR-Pool-1' element={<ThreeSixtyLGRPool1 />} />
              <Route path='/360-LGR-Lobby' element={<ThreeSixtyLGRLobby />} />
              <Route path='/360-LGR-Sales-Office' element={<ThreeSixtyLGRSalesOffice />} />
              <Route path='/360-LGR-Coffee-Lounge' element={<ThreeSixtyLGRCoffeeLounge />} />
              <Route path='/360-LGR-Lobby-Ext' element={<ThreeSixtyLGRLobbyExt />} />
              <Route path='/360-LGR-Underwater' element={<ThreeSixtyLGRUnderWaterPool />} />
              <Route path='/360-LGR-Entrance' element={<ThreeSixtyLGREntrance />} />
              <Route path='/360-LGR-Waiting-Area' element={<ThreeSixtyLGRWaitingArea />} />
              <Route path='/360-LGR-ToElev' element={<ThreeSixtyLGRToElev />} />
              <Route path='/360-LGR-11th' element={<ThreeSixtyLGR11thFloor />} />
              <Route path='/360-LGR-11th-1' element={<ThreeSixtyLGR11thFloor1 />} />
              <Route path='/360-LGR-PoolFromCL' element={<ThreeSixtyLGRPoolFromCL />} />
              <Route path='/360-LGR-11th-3' element={<ThreeSixtyLGR11thFloor3 />} />
              <Route path='/360-LGR-11th-4' element={<ThreeSixtyLGR11thFloor4 />} />
              <Route path='/360-LGR-11th-5' element={<ThreeSixtyLGR11thFloor5 />} />
              <Route path='/360-LGR-Gym' element={<ThreeSixtyLGRGym />} />
              <Route path='/360-LGR-1st-PoolBack' element={<ThreeSixtyLGR1stPoolBack />} />
              <Route path='/360-LGR-Gameroom' element={<ThreeSixtyLGRGameRoom />} />
              <Route path='/360-LGR-Inside-Elevator' element={<ThreeSixtyInsideElevator />} />
              <Route path='/360-LGR-15th-Floor' element={<ThreeSixty15thFloor />} />
              <Route path='/360-LGR-15th-Floor2' element={<ThreeSixty15thFloor2 />} />
              <Route path='/360-LGR-15th-Floor3' element={<ThreeSixty15thFloor3 />} />
              <Route path='/360-LGR-15th-Floor4' element={<ThreeSixty15thFloor4 />} />
              <Route path='/360-LGR-15th-Floor5' element={<ThreeSixty15thFloor5 />} />
              <Route path='/360-LGR-15th-Floor6' element={<ThreeSixty15thFloor6 />} />
              <Route path='/360-LGR-15th-Floor7' element={<ThreeSixty15thFloor7 />} />
              <Route path='/360-LGR-15th-Floor8' element={<ThreeSixty15thFloor8 />} />
              <Route path='/360-LGR-16th-Floor' element={<ThreeSixty16thFloor />} />
              <Route path='/360-LGR-16th-Floo-Cityview' element={<ThreeSixty16thFloorCityView />} />
              <Route path='/360-LGR-16th-Floor2' element={<ThreeSixty16thFloor2 />} />
              <Route path='/360-LGR-SLDining' element={<ThreeSixtySLDining />} />
              <Route path='/360-LGR-SLDining2' element={<ThreeSixtySLDining2 />} />
              <Route path='/360-LGR-15thFloorPool' element={<ThreeSixty15thFloorPool />} />
              <Route path='/360-LGR-16th-Floor3' element={<ThreeSixty16thFloor3 />} />
              <Route path='/360-LGR-16th-Balcony' element={<ThreeSixty16thFloorBalcony />} />
              <Route path='/360-LGR-ConferenceRoom' element={<ThreeSixtyConferenceRoom />} />
              <Route path='/360-LGR-Pool-CityView' element={<ThreeSixtyPoolCityView />} />
             {/* <Route path='/Newportal/maintenance' element={<NewMaintenancePortal />} /> */}

              <Route path='*' element={<NotFound />} />
            </Route>
          </Routes>

          {!currentURL.includes("admin") && !currentURL.includes("portal") && !currentURL.includes("feedback") && !currentURL.includes("referral") && <Footer />}
          
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
